import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { Formik } from 'formik';

import QR from './QRcode.png';
import logo from './logo.png'
import Modal from 'react-awesome-modal';
import OtpInput from 'react-otp-input';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      contactNo: "",
      address: "",
      // qrCode: QR,
      qrCode: '',
      qrName: '',
      checkbox: '',
      showQR: false,
      isSelected1: false,
      isSelected2: false,
      isSelected3: false,
      alert: false,
      otp: '',
      sessionId: '',
      incorrectOTP: false,
      defaultNumber: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      getState: '',
      postCode: '',
      instructions: false,
    };
  };

  idChange = (event) => {
    this.setState({ id: event.target.value })
  }

  emailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  firstNameChange = (event) => {
    this.setState({ firstName: event.target.value })
  }

  lastNameChange = (event) => {
    this.setState({ lastName: event.target.value })
  }

  // contactNoChange = (event) => {
  //   this.setState({ contactNo: event.target.value.replace(/\D/, '') })
  // }

  addressLine1 = (event) => {
    this.setState({ addressLine1: event.target.value });
  }

  addressLine2 = (event) => {
    this.setState({ addressLine2: event.target.value });
  }

  city = (event) => {
    this.setState({ city: event.target.value });
  }
  getState = (event) => {
    this.setState({ getState: event.target.value });
  }
  postCode = (event) => {
    this.setState({ postCode: event.target.value.replace(/\D/, '') });
  }

  addressChange = (event) => {
    this.setState({ address: event.target.value })
  }

  // enterPressed = (event) => {
  //   console.log("enter pressed")
  //   var code = event.keyCode || event.which;
  //   if (code == 13) {
  //     this.sentOTP();
  //   }
  // }

  getQR = () => {

    var address = this.state.addressLine1 + "," + this.state.addressLine2 + "," +
      this.state.city + "," + this.state.getState + "," + this.state.postCode;

    var data = {
      "id": this.state.id,
      "email": this.state.email,
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "contactNo": this.state.contactNo,
      "address": address
    }

    axios.post("http://54.153.168.107:5010/Barcode/createqrcode", data, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        var resp = res.data;
        if (resp.responseStatus == "SUCCESS") {
          this.setState({ showQR: true });
          this.setState({ qrCode: "data:image/png;base64, " + resp.qrCode })
          this.setState({ qrName: this.state.firstName });
          this.setState({ instructions: true });
          // this.setState({ id: "" });
          // this.setState({ email: "" });
          // this.setState({ firstName: "" });
          // this.setState({ lastName: "" });
          // this.setState({ contactNo: "" });
          // this.setState({ address: "" });
          // this.setState({ incorrectOTP: false });
          // this.setState({ defaultNumber: true });
          // this.setState({ defaultNumber: false });
          // this.setState({ isSelected: false });
          this.sentEmail();
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })

  }

  sentOTP = () => {
    var apiKey = "fbfe968c-edd5-11ea-9fa5-0200cd936042";
    var number = this.state.contactNo;
    axios.post("https://2factor.in/API/V1/" + apiKey + "/SMS/" + number + "/AUTOGEN")
      .then(res => {
        var response = res.data;
        // console.log("response is here " + JSON.stringify(res));
        // console.log("Details is here " + response.Details);
        this.setState({ sessionId: response.Details });
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })

    this.setState({ alert: true })
  }

  sentEmail = () => {
    // axios({
    //   method: "POST",
    //   url: "http://localhost/mailer/mailer.php",
    //   data: {
    //     name: "easethroughqrcode",
    //     image: this.state.qrCode
    //   }
    // }).then((response) => {
    //   // if (response.data.msg === 'success') {

    //   // } else if (response.data.msg === 'fail') {
    //   //   alert("Message failed to send.")
    //   // }
    // })

    var bodyFormData = new FormData();
    bodyFormData.append('name', "easethroughqrcode");
    bodyFormData.append('image', this.state.qrCode);
    bodyFormData.append('email', this.state.email);
    axios({
      method: 'post',
      url: 'http://quickregister.digit9tech.com/mail/send.php',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {

    })

  }

  validateOTP = () => {

    var apiKey = "fbfe968c-edd5-11ea-9fa5-0200cd936042";
    var session = this.state.sessionId;// "502b96b2-f9b2-476f-a477-8d5e73893a68";//
    var otp = this.state.otp;//846331;
    axios.get("https://2factor.in/API/V1/" + apiKey + "/SMS/VERIFY/" + session + "/" + otp + "")
      .then(res => {
        var response = res.data;
        if (response.Details == 'OTP Matched') {
          console.log("otp matched");
          this.setState({ instructions: true });
          this.getQR();
          this.setState({ alert: false })
        }
        // console.log("response is here " + JSON.stringify(res));
        // console.log("Details is here " + response.Details);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", JSON.stringify(err.response.data.Details));
        var message = err.response.data.Details;
        if (message == "OTP Mismatch") {
          this.setState({ incorrectOTP: true });
        }
      })
    this.setState({ alert: true })

  }

  checkBoxSelected1 = (event) => {
    this.setState({ isSelected1: event.target.checked })
  }

  checkBoxSelected2 = (event) => {
    this.setState({ isSelected2: event.target.checked })
  }
  checkBoxSelected3 = (event) => {
    this.setState({ isSelected3: event.target.checked })
  }

  reload = (event) => {
    window.location.reload();
  }

  onChangeHandler = (data) => {

    console.log("phone number " + data);

    // var number = data.intlPhoneNumber.replace(/\s/g, "");

    this.setState({ contactNo: "+"+data });

  }

  download = () => {
    var a = document.createElement("a");
    a.href = this.state.qrCode;
    a.download = this.state.qrName + "_easethroughqrcode.png";
    a.click();
  }

  handleChange = (otp) => {
    this.setState({ otp })
  };

  render() {
    return (
      <div className="container-fluid App">
        {/* <div style={background} > */}
        <div className="row" style={{ borderBottom: '#fff 1px solid', background: 'rgba(256,256,256,.7)' }}>
          <div style={{ padding: '1%' }}>
            <img src={logo} style={{ width: '52px' }}></img>
          </div>
          <div style={{ display: 'inline-block', alignSelf: 'center' }}>
            <h1>
              <a style={{ color: '#000', fontSize: '20px', fontFamily: 'American Typewriter Regular', letterSpacing: '1px', fontWeight: '600' }}>Digit9 Tech Labs</a></h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6" >
            {this.state.instructions ?
              <div style={{ marginTop: '10%' }}>
                <h3>Instructions</h3>
                <ul>
                  <li>Open the QR Code reader on your phone.</li>
                  <li>Hold your device over a QR Code so that it’s clearly visible within your smartphone’s screen.</li>
                  <li>The phone automatically scans the code.</li>
                  <li>Position your phone so the QR code appears in the digital viewfinder.</li>
                  <li>Check to see if your Android phone supports QR code scanning.</li>
                </ul>
                {/* <Button variant="primary" onClick={this.getQR} type="submit">
                  Generate QR
                    </Button> */}
              </div>
              :
              <div>
                <Formik
                  validate={(values) => {
                    let errors = {};

                    if (this.state.id == "") {
                      errors.id = "Passport or Driver's License number is required";
                      return errors;
                    }

                    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.email)) {
                      errors.email = 'Invalid email';
                      return errors;
                    };

                    if (this.state.firstName == "") {
                      errors.firstName = 'Firstname is required';
                      return errors;
                    }

                    if (this.state.lastName == "") {
                      errors.lastName = 'Lastname is required';
                      return errors;
                    }
                    if (this.state.contactNo == "") {
                      errors.contactNo = 'Contact No is required';
                      return errors;
                    }

                    if (this.state.addressLine1 == "") {
                      errors.addressLine1 = "Address Line 1 is required";
                      return errors;
                    }

                    // if (this.state.addressLine2 == "") {
                    //   errors.addressLine2 = "Address Line 2 is required";
                    //   return errors;
                    // }

                    if (this.state.city == "") {
                      errors.city = "Suburb/City is required";
                      return errors;
                    }

                    if (this.state.getState == "") {
                      errors.getState = "State is required";
                      return errors;
                    }

                    if (this.state.postCode == "") {
                      errors.postCode = "Post code is required";
                      return errors;
                    }


                    if (!this.state.isSelected1) {
                      errors.checkbox1 = 'The terms must be accepted.'
                      return errors;
                    }

                    if (!this.state.isSelected2) {
                      errors.checkbox2 = 'The terms must be accepted.'
                      return errors;
                    }
                    if (!this.state.isSelected3) {
                      errors.checkbox3 = 'The terms must be accepted.'
                      return errors;
                    }

                    return errors;
                  }}
                  onSubmit={() => {
                    this.sentOTP();
                    // this.getQR();
                  }}
                  initialValues={{
                    state: '',
                    photo: '',
                    nameText: '',
                    lastName: 'Otto',
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (

                      <Form noValidate onSubmit={handleSubmit} style={{ margin: "10% 0 auto" }}>
                        <div className="row">
                          <div className="col-sm-6">
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Passport or Driver's License number"
                                value={this.state.id}
                                onChange={this.idChange}
                                isInvalid={!!errors.id} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.id}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.emailChange}
                                isInvalid={!!errors.email} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Firstname"
                                value={this.state.firstName}
                                onChange={this.firstNameChange}
                                isInvalid={!!errors.firstName} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.firstName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Lastname"
                                value={this.state.lastName}
                                onChange={this.lastNameChange}
                                isInvalid={!!errors.lastName} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.lastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              {/* <IntlTelInput
                                removeToken={true}
                                preferredCountries={['IN', 'AU']}
                                defaultCountry={'AU'}
                                defaultValue={''}
                                // reset={this.state.defaultNumber}
                                paginate={3}
                                onChange={(data) => this.onChangeHandler(data)}
                              /> */}
                              <PhoneInput
                                enableSearch={true}
                                country={'au'}                                
                                inputStyle={{width: '100%'}}
                                value={this.state.phone}
                                onChange={(data) => this.onChangeHandler(data)}
                              />
                              <Form.Control type="text" pattern="[0-9]*"
                                placeholder="Enter Contact No"
                                value={this.state.contactNo}
                                style={{ display: 'none' }}
                                onChange={this.contactNoChange}
                                isInvalid={!!errors.contactNo} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.contactNo}
                              </Form.Control.Feedback>
                            </Form.Group>

                          </div>

                          <div className="col-sm-6">

                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Address Line 1"
                                value={this.state.addressLine1}
                                onChange={this.addressLine1}
                                isInvalid={!!errors.addressLine1} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.addressLine1}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Address Line 2"
                                value={this.state.addressLine2}
                                onChange={this.addressLine2}
                                isInvalid={!!errors.addressLine2} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.addressLine2}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Suburb/City"
                                value={this.state.city}
                                onChange={this.city}
                                isInvalid={!!errors.city} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.city}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="State"
                                value={this.state.getState}
                                onChange={this.getState}
                                isInvalid={!!errors.getState} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.getState}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formGridEmail">
                              <Form.Control type="text"
                                placeholder="Postcode"
                                value={this.state.postCode}
                                onChange={this.postCode}
                                isInvalid={!!errors.postCode} />
                              <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                                {errors.postCode}
                              </Form.Control.Feedback>
                            </Form.Group>

                          </div>
                        </div>

                        {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Control as="textarea" rows="3"
                        placeholder="Address"
                        value={this.state.address}
                        onChange={this.addressChange}
                        isInvalid={!!errors.address} />
                      <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check type="checkbox"
                            checked={this.state.isSelected1}
                            onChange={this.checkBoxSelected1}
                            isInvalid={!!errors.checkbox1}
                            label="I accept the Terms and Conditions" />
                          <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                            {errors.checkbox1}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check type="checkbox"
                            checked={this.state.isSelected2}
                            onChange={this.checkBoxSelected2}
                            isInvalid={!!errors.checkbox2}
                            label="I confirm the information provided is correct and true" />
                          <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                            {errors.checkbox2}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check type="checkbox"
                            checked={this.state.isSelected3}
                            onChange={this.checkBoxSelected3}
                            isInvalid={!!errors.checkbox3}
                            label="I accept the Terms" />
                          <Form.Control.Feedback type="invalid" style={{ textAlign: 'initial' }}>
                            {errors.checkbox3}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          Generate QR
                    </Button>
                      </Form>
                    )}
                </Formik>
                <div className="row" style={{ height: '20px' }}></div>

              </div>
            }


          </div>
          <div className="col-sm-6">
            {this.state.showQR ?
              <div >
                <div className="row" style={{ marginTop: '10%' }}>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <img src={this.state.qrCode} style={{ width: '100%' }} />
                  </div>
                  <div className="col-sm-2"></div>
                </div>
                <div className="row" style={{ marginTop: '4%' }}>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <Button style={{ float: 'left' }} variant="primary" onClick={this.reload} type="submit">
                      Generate Another QR
                    </Button>
                    <button className="btn btn-primary" style={{ float: 'right' }} onClick={this.download}>Download QR</button>
                  </div>
                  <div className="col-sm-2"></div>

                  {/* <div className="col-1"></div>
                  <div className="col-5">
                    <Button style={{ float: 'right' }} variant="primary" onClick={this.getQR} type="submit">
                      Generate Another QR
                    </Button>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-3">
                    <button className="btn btn-primary" style={{ float: 'right' }} onClick={this.download}>Download QR</button>
                  </div>
                  <div className="col-sm-2"></div> */}
                </div>
                <div className="row" style={{ height: '20px' }}></div>
              </div>
              : null}
          </div>

          <Modal
            visible={this.state.alert}
            width="500"
            onClickAway={() => this.setState({ alert: false })}
            effect="fadeInUp"
            style={{ backgroundColor: "#B9E8EF" }}>
            <div className="row">
              <button class="close" onClick={() => this.setState({ alert: false })}
                style={{ position: 'absolute', right: '8px' }}>×</button>
            </div>
            <div className="row">
              <div id="dialog" style={{ padding: '5%', textAlign: 'center' }}>
                {/* <h3 class="heading">Please enter the 6-digit verification code we sent via SMS:</h3> */}
                <h3 className="heading">Please enter the otp to generate the unique encrypted QR. This QR code can be used to enrol or register</h3>
                <div className="row phone" style={{ marginTop: '5%',display: 'inline-block',verticalAlign: 'middle' }}>
                  <div className='col-sm-2'></div>
                  <OtpInput
                    value={this.state.otp}
                    onChange={this.handleChange}
                    numInputs={6}
                    inputStyle={{ width: '3rem', height: '4rem' }}
                    separator={<span>-</span>}
                  />

                </div>
                {this.state.incorrectOTP ?
                  <div>
                    <p style={{ color: 'red', marginTop: '4%' }}>The OTP you've entered is incorrect.Please try again.</p>
                    <button onClick={this.validateOTP}
                      class="btn btn-primary btn-embossed">Verify</button>
                  </div> :
                  <div style={{ marginTop: '5%' }}>
                    otp will be send on your phone via SMS.<br />
                    <br />
                    <button onClick={this.validateOTP}
                      class="btn btn-primary btn-embossed">Verify</button>
                  </div>
                }
                <div>
                  Didn't receive the code?<br />
                  <a href="#" onClick={this.sentOTP}>Send code again</a><br />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      // </div>
    )
  };
}

export default App;

